import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import WorkIcon from "@mui/icons-material/Work";
import { Stack } from "@mui/material";
import { type Worker } from "@src/appV2/Worker/api/types";

import { APP_V2_APP_EVENTS, useToast } from "../lib";
import { logEvent } from "../lib/analytics";
import { useLogEffect } from "../lib/analytics/useLogEffect";
import { PullToRefresh } from "../lib/PullToRefresh/PullToRefresh";
import { useCreateOrGetPlacementCandidate } from "./api/useCreateOrGetPlacementCandidate";
import { useUpdatePlacementCandidate } from "./api/useUpdatePlacementCandidate";
import { ActivatedPlacementCandidate } from "./components/ActivatedPlacementCandidate";
import { JobPreferenceForm } from "./components/JobPreferenceForm";
import { OnboardingPlacementCandidate } from "./components/OnboardingPlacementCandidate";
import { PendingPlacementCandidate } from "./components/PendingPlacementCandidate";
import { PlacementCandidateError } from "./components/PlacementCandidateError";
import { PlacementCandidateSkeleton } from "./components/PlacementCandidateSkeleton";
import { PlacementCandidateSwitch } from "./components/PlacementCandidateSwitch";
import { UploadedCandidateRequirements } from "./components/UploadedCandidateRequirements";
import { PlacementCandidateStatus } from "./types";

interface PlacementCandidatePageProps {
  worker: Worker;
}

export function PlacementCandidatePage(props: PlacementCandidatePageProps) {
  const { worker } = props;
  const { showSuccessToast } = useToast();
  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
    refetch: refetchPlacementCandidate,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );

  useLogEffect(
    isPlacementCandidateSuccess && isDefined(placementCandidate) && isDefined(placementCandidate.id)
      ? APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_PAGE_VIEWED
      : undefined,
    {
      placementCandidateId: placementCandidate?.id,
    }
  );
  const { mutateAsync: updatePlacementCandidate, isLoading: isUpdatePlacementCandidateLoading } =
    useUpdatePlacementCandidate();

  if (isPlacementCandidateLoading) {
    return <PlacementCandidateSkeleton />;
  }

  if (!isPlacementCandidateSuccess || !isDefined(placementCandidate.id)) {
    return (
      <>
        <PullToRefresh onRefresh={refetchPlacementCandidate} />
        <PlacementCandidateError />
      </>
    );
  }

  return (
    <>
      <PullToRefresh onRefresh={refetchPlacementCandidate} />
      <Stack sx={{ overflowY: "auto", p: 2 }} flex={1}>
        <Stack spacing={2} flex={1}>
          <PlacementCandidateSwitch
            checked={placementCandidate.status !== PlacementCandidateStatus.DISABLED}
            isLoading={isUpdatePlacementCandidateLoading || isPlacementCandidateLoading}
            onChange={async (event) => {
              if (isUpdatePlacementCandidateLoading || isPlacementCandidateLoading) {
                return;
              }

              logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_STATUS_UPDATED, {
                placementCandidateId: placementCandidate.id,
                status: event.target.checked
                  ? PlacementCandidateStatus.ONBOARDING
                  : PlacementCandidateStatus.DISABLED,
              });

              await updatePlacementCandidate({
                placementCandidateId: placementCandidate.id,
                status: event.target.checked
                  ? PlacementCandidateStatus.ONBOARDING
                  : PlacementCandidateStatus.DISABLED,
                workerId: worker.userId,
              });
              await refetchPlacementCandidate();
            }}
          />

          {placementCandidate.status === PlacementCandidateStatus.DISABLED && (
            <Stack flex={1} justifyContent="center" alignItems="center" spacing={0.5}>
              <WorkIcon sx={{ fontSize: 64, color: "primary.main" }} />
              <Stack spacing={0.25} justifyContent="center" alignItems="center">
                <Text variant="body1">Get hired by local workplaces around you</Text>
                <Text variant="body2" align="center">
                  Once enabled, local workplaces will be able to view your profile, and contact you
                  with open job opportunities.
                </Text>
              </Stack>
            </Stack>
          )}

          <Stack spacing={2}>
            {placementCandidate.status === PlacementCandidateStatus.PENDING && (
              <PendingPlacementCandidate />
            )}

            {placementCandidate.status === PlacementCandidateStatus.ACTIVATED && (
              <ActivatedPlacementCandidate />
            )}
            {placementCandidate.status !== PlacementCandidateStatus.DISABLED && (
              <JobPreferenceForm
                initialJobTypes={placementCandidate.jobTypesInterested}
                initialShiftTypes={placementCandidate.shiftTypesInterested}
                initialAddressDetails={placementCandidate.preferredAreas?.[0]}
                worker={worker}
                onSave={async (data) => {
                  logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_JOB_PREFERENCES_SUBMITTED, {
                    placementCandidateId: placementCandidate.id,
                    status: placementCandidate.status,
                    jobTypes: data.jobTypes,
                    shiftTypes: data.shiftTypes,
                    preferredAreas: data.addressObject,
                  });
                  await updatePlacementCandidate({
                    placementCandidateId: placementCandidate.id,
                    workerId: worker.userId,
                    jobTypesInterested: data.jobTypes,
                    shiftTypesInterested: data.shiftTypes,
                    preferredAreas: data.addressObject ? [data.addressObject] : undefined,
                  });
                  showSuccessToast("Your job preferences have been updated.");
                  await refetchPlacementCandidate();
                }}
              />
            )}

            {placementCandidate.status === PlacementCandidateStatus.ONBOARDING &&
              ((isDefined(placementCandidate.jobTypesInterested) &&
                placementCandidate.jobTypesInterested.length > 0) ||
                (isDefined(placementCandidate.shiftTypesInterested) &&
                  placementCandidate.shiftTypesInterested.length > 0)) && (
                <OnboardingPlacementCandidate
                  placementCandidateId={placementCandidate.id}
                  introductionUrl={placementCandidate.introductionUrl}
                  worker={worker}
                />
              )}
          </Stack>

          {(placementCandidate.status === PlacementCandidateStatus.PENDING ||
            placementCandidate.status === PlacementCandidateStatus.ACTIVATED) && (
            <UploadedCandidateRequirements
              placementCandidateId={placementCandidate.id}
              introductionUrl={placementCandidate.introductionUrl}
              resumeUrl={placementCandidate.resumeUrl}
              worker={worker}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
}
